import { Ref, SetupContext } from "@vue/composition-api";
import { isEqual } from "lodash";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

interface IUseCheckChangesModal {
  checkChangesBeforeClose: () => boolean;
  checkChangesBeforeSave: () => boolean;
}

const useCheckChangesModal = (
  context: SetupContext,
  formValues: Ref<Record<string, unknown>>,
  changedFormValues: Ref<Record<string, unknown>>,
  convertSaveCardObject: (data: Record<string, unknown>) => Record<string, unknown>
): IUseCheckChangesModal => {
  const getResultSaveData = (data: Record<string, unknown>) => {
    const resultData = {
      id: data.id || formValues.value.id,
      ...data,
    };
    return convertSaveCardObject(resultData);
  };

  const checkChangesBeforeClose = (): boolean => {
    if (!isEqual(getResultSaveData(formValues.value), changedFormValues.value)) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "WarningModal", {
          cbOnDelete: () => {
            context.emit("close");
          },
        })
      );
      return false;
    }
    return true;
  };

  const checkChangesBeforeSave = (): boolean => {
    if (!isEqual(getResultSaveData(formValues.value), changedFormValues.value)) {
      return true;
    }
    return false;
  };

  return {
    checkChangesBeforeClose,
    checkChangesBeforeSave,
  };
};

export default useCheckChangesModal;
