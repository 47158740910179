









import { defineComponent } from "@vue/composition-api";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";

export default defineComponent({
  name: "Footer",
  props: {
    pluralFormTitles: {
      type: Array,
      default: () => [],
    },
    sectionName: {
      type: String,
      default: "",
    },
    isShowSelectedLength: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isSelectAll(): boolean {
      return this.$store.getters[`${this.sectionName}/isSelectAll`];
    },
    selectedIds(): Record<string, boolean> {
      return this.$store.getters[`${this.sectionName}/selectedIds`];
    },
    totalLength(): number {
      return this.$store.getters[`${this.sectionName}/totalLength`];
    },
    selectedLength(): number {
      return this.isSelectAll ? this.totalLength : Object.values(this.selectedIds).filter(Boolean).length;
    },
    selectedLengthTitle(): string {
      return this.pluralFormTitles.length === 1
        ? (this.pluralFormTitles as string[])[0]
        : getPluralNoun(this.selectedLength, ...(this.pluralFormTitles as string[]));
    },
  },
});
