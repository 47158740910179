






































































import { defineComponent, PropType } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import mask from "@monorepo/utils/src/directives/mask";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";

export default defineComponent({
  name: "CardModalChapter",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CheckboxElement,
  },
  directives: {
    mask,
  },
  data() {
    return {
      ModalType,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modalElements: {
      type: Array as PropType<IModalElementType[]>,
      default: () => [],
    },
    modalType: {
      type: String,
      default: "",
    },
    formValues: {
      type: Object,
      default: () => ({}),
    },
    hideFieldsObject: {
      type: Object,
      default: () => ({}),
    },
    isShowErrorText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabledElement() {
      return (element: IModalElementType) => {
        return (element.disabledTypes || [ModalType.READ]).includes(this.modalType) || element.fieldType === "password";
      };
    },
    isShowElement() {
      return (element: IModalElementType) => {
        const isHideElement = this.hideFieldsObject[element.value];
        return (
          (!element.modalType || this.modalType === element.modalType) &&
          (!element.isHideIfEmptyValue || this.formValues[element.value]) &&
          !isHideElement
        );
      };
    },
    className() {
      return (element: IModalElementType) => {
        return !element.modalType || this.modalType === element.modalType ? element.className : "";
      };
    },
  },
  methods: {
    clickElementCb(element: IModalElementType) {
      if (!element.isLink) {
        return;
      }
      this.$emit("clickElement", element);
    },
    changeElementCb(element: IModalElementType) {
      this.$emit("changeElement", element);
    },
  },
});
