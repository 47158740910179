




























import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { defineComponent } from "@vue/composition-api";
import { mapGetters, mapActions } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";

export default defineComponent({
  name: "CardModalToggleViewSize",
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cardMode: CardMode,
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList", "isCardModeLoaded"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
  },
  methods: {
    ...mapActions("auth", ["loadCardModes", "setCardMode"]),
    toggleViewMode(mode: CardMode) {
      this.setCardMode({ [this.section]: mode });
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_VIEW_MODE);
    },
  },
  mounted() {
    if (!this.isCardModeLoaded) {
      this.loadCardModes();
    }
  },
});
