import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IDocTypeFields {
  ID: string;
  CHECKBOX: string;
  CHAPTER: string;
  SECTION: string;
  SECTION_NAME: string;
  FILTER_CHAPTER: string;
  FILTER_SECTION: string;
  NAME: string;
  ARTICLE: string;
  STORAGE_TERM_VALUE: string;
  COMMENT: string;
  FILTER_KEYWORD: string;
  FILTER_SEARCH_MORPH: string;
  FILTER_SEARCH_NGRAMED: string;
  FILTER_SEARCH_SYNONYM: string;
  FILTER_SEARCH_FREQUENCY: string;
}

export const fields: IDocTypeFields = {
  CHECKBOX: headerCheckboxValue,
  ID: "id",
  CHAPTER: "chapter",
  SECTION: "section",
  SECTION_NAME: "sectionName",
  NAME: "name",
  ARTICLE: "articleNumber",
  STORAGE_TERM_VALUE: "storageTermValue",
  COMMENT: "comment",
  FILTER_CHAPTER: "subsectionId",
  FILTER_SECTION: "sectionId",
  FILTER_KEYWORD: "keyword",
  FILTER_SEARCH_MORPH: "morphology",
  FILTER_SEARCH_NGRAMED: "ngramed",
  FILTER_SEARCH_SYNONYM: "synonymysed",
  FILTER_SEARCH_FREQUENCY: "frequency-term",
};
