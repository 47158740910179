import { render, staticRenderFns } from "./ExportBtn.vue?vue&type=template&id=064fbd86&scoped=true&"
import script from "./ExportBtn.vue?vue&type=script&lang=ts&"
export * from "./ExportBtn.vue?vue&type=script&lang=ts&"
import style0 from "./ExportBtn.vue?vue&type=style&index=0&id=064fbd86&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064fbd86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VProgressCircular,VTooltip})
