













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "OpenCardButton",
  props: {
    title: {
      type: String,
      default: "Открыть карточку",
    },
    icon: {
      type: String,
    },
    isCapitalize: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
});
