




import { defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "Footer",
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
});
