





















import { defineComponent } from "@vue/composition-api";
import IconList from "@monorepo/inventory/src/views/InventoryView/components/icons/IconList.vue";
import IconScheme from "@monorepo/inventory/src/views/InventoryView/components/icons/IconScheme.vue";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export default defineComponent({
  name: "ToggleDocumentView",
  components: {
    IconList,
    IconScheme,
  },
  props: {
    isShowNewTable: {
      type: Boolean,
      default: false,
    },
    isShowTree: {
      type: Boolean,
      default: true,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      educationIds,
    };
  },
  computed: {
    currentViewTab(): number {
      const viewMode = this.$store.getters[`${this.sectionName}/viewMode`];
      const index = this.tabItems.findIndex((item) => item.viewMode === viewMode?.typeDisplay);
      return index !== -1 ? index : 0;
    },
    tabEducationId(): string {
      switch (this.currentViewTab) {
        case 0:
          return this.educationIds.treeViewTab;
        case 1:
          return this.educationIds.tableViewTab;
        default:
          return "";
      }
    },
    tabItems(): any[] {
      return [
        {
          tooltip: "Представление в виде таблицы",
          component: IconList,
          viewMode: ViewMode.TABLE,
        },
        ...(this.isShowTree
          ? [
              {
                tooltip: "Представление в виде дерева",
                component: IconScheme,
                viewMode: ViewMode.TREE,
              },
            ]
          : []),
        ...(this.isShowNewTable
          ? [
              {
                icon: "mdi-eye",
                tooltip: "Режим предпросмотра",
                viewMode: ViewMode.SEARCH,
              },
            ]
          : []),
      ];
    },
  },
  methods: {
    refreshData() {
      this.$store.dispatch(`${this.sectionName}/refreshData`);
    },
    clearFilters() {
      this.$store.dispatch(`${this.sectionName}/clearFilters`);
    },
    setSelectedIds() {
      this.$store.dispatch(`${this.sectionName}/setSelectedIds`, {});
    },
    changeViewTab(event: number) {
      const typeDisplay = this.tabItems[event]?.viewMode || ViewMode.TABLE;
      this.$store.dispatch(`${this.sectionName}/changeViewMode`, typeDisplay);
      this.clearFilters();
      this.refreshData();
      this.setSelectedIds();
      this.$emit("change-view", typeDisplay);
    },
  },
});
