export const elementStatuses = [
  {
    title: "Недействующий",
    value: "INACTIVE",
  },
  {
    title: "Черновик",
    value: "DRAFT",
  },
  {
    title: "Действующий",
    value: "ACTIVE",
  },
];
