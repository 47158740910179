import { v4 as uuid } from "uuid";

export const headerCheckboxValue = "checkbox";

export const headerCheckboxObject = (): { value: string; defaultWidth: number; isSorted: boolean; isHandle: boolean; id: string } => ({
  value: headerCheckboxValue,
  defaultWidth: 50,
  isSorted: false,
  isHandle: false,
  id: uuid(),
});
