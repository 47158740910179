import { ComponentInstance, computed, onBeforeMount, onBeforeUnmount, watch, ComputedRef, WritableComputedRef } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { DELETE_EVENTS_ACTIONS } from "@monorepo/utils/src/eventBus/events/deleteEvents";
import { DictionaryStatuses } from "@monorepo/utils/src/variables/dictionaryStatuses";
import Vue from "vue";

const checkVisible = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom + 88 <= (window.innerHeight || document.documentElement.clientHeight);
};

interface IUseTable {
  clickCheckbox: (id: string) => null;
  isElementDeleted: ComputedRef<(status: string) => boolean>;
  selectedIdsElements: WritableComputedRef<Record<string, boolean>>;
}

const useTable = (root: ComponentInstance, moduleName: string, customFieldNames?: { selectedKey?: string; dataField?: string }): IUseTable => {
  const store = root.$store;

  const selectedIds = computed(() => store.getters[`${moduleName}/selectedIds`]);
  const data = computed(() => store.getters[`${moduleName}/${customFieldNames?.dataField || "data"}`]);
  const isSelectAll = computed(() => store.getters[`${moduleName}/isSelectAll`]);
  const isElementDeleted = computed(() => {
    return (status: string) => {
      return status === DictionaryStatuses.DELETED || status === DictionaryStatuses.CONCATED;
    };
  });
  const selectedIdsElements = computed({
    get(): Record<string, boolean> {
      return selectedIds.value;
    },
    set(value: Record<string, boolean>) {
      setSelectedIds(value);
    },
  });

  const setSelectedIds = (value: Record<string, boolean>) => {
    store.dispatch(`${moduleName}/setSelectedIds`, value);
  };
  const setIsSelectAll = (value: boolean | null) => {
    store.dispatch(`${moduleName}/setIsSelectAll`, value);
  };

  const deleteItems = () => {
    const elements = document.querySelectorAll(".monitoring-table__tr-active");
    const visibleElements = Object.values(elements).filter((el) => checkVisible(el)) as HTMLElement[];
    visibleElements.forEach((element: HTMLElement) => {
      element.classList.add("monitoring-table__tr-active_deleted");
    });
  };

  const moveToHeadersScroll = () => {
    const element = document.getElementById("virtual-scroll-table");
    const headerElement = document.getElementById("table-header-wrap");
    if (element && headerElement) {
      element.scrollLeft = headerElement.scrollLeft;
    }
  };

  const clickCheckbox = (id: string) => {
    if (isSelectAll.value) {
      setIsSelectAll(null);
    }

    selectedIdsElements.value = {
      ...selectedIdsElements.value,
      [id]: !selectedIdsElements.value[id],
    };

    return null;
  };

  const clearAll = () => {
    selectedIdsElements.value = {};
  };

  const selectAll = (value: Record<string, unknown>[]) => {
    selectedIdsElements.value = value.reduce((result: Record<string, boolean>, item: Record<string, unknown>) => {
      const key = item[customFieldNames?.selectedKey || "id"] as string;
      result[key] = true;
      return result;
    }, {});
  };

  onBeforeMount(() => {
    eventBus.$on(DELETE_EVENTS_ACTIONS.DELETE, deleteItems);
  });

  onBeforeUnmount(() => {
    eventBus.$off(DELETE_EVENTS_ACTIONS.DELETE, deleteItems);
  });

  watch(data, (value: Record<string, unknown>[]) => {
    if (isSelectAll.value) {
      selectAll(value);
    }
    Vue.nextTick(() => {
      moveToHeadersScroll();
    });
  });

  watch(isSelectAll, (value: boolean | null) => {
    if (value) {
      selectAll(data.value);
    } else if (value === false) {
      clearAll();
    }
  });

  return {
    clickCheckbox,
    isElementDeleted,
    selectedIdsElements,
  };
};

export default useTable;
