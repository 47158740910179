import { fields } from "@monorepo/utils/src/variables/projectsData/docTypeView/fields";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
// import { searchMenuItems } from "@monorepo/inventory/src/views/EadView/constants/searchMenuItems";

export const filtersElements = [
  // {
  //   value: fields.FILTER_KEYWORD,
  //   placeholder: "Поисковый запрос",
  //   tooltip: "Ручной ввод, поиск по маске",
  //   type: "autocomplete",
  //   showSelectedKey: "code",
  //   autocompleteActionName: "getSearchAutocomplete",
  //   className: "filters__full-block",
  //   appendInnerIcon: "mdi-cog",
  //   iconTooltip: "Найденные фрагменты будут подсвечены в результатах поиска соответствующим найстройке поиска цветом",
  //   menuItems: searchMenuItems,
  // },
  // {
  //   value: fields.ID,
  //   title: "Идентификатор",
  //   type: "text",
  //   width: 268,
  // },
  {
    value: fields.ARTICLE,
    title: "Статья",
    type: "text",
    width: 268,
  },
  {
    value: fields.FILTER_SECTION,
    title: "Название секции",
    type: "treeFilter",
    width: 380,
    selectItemsField: "sectionsTreeItems",
    availableDocumentTypes: [ViewMode.TABLE, ViewMode.SEARCH],
  },
  {
    value: fields.NAME,
    title: "Вид документа",
    type: "text",
    width: 268,
  },
  {
    value: fields.STORAGE_TERM_VALUE,
    title: "Срок хранения",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "select",
    selectItemsField: "storageTerms",
    width: 350,
  },
];
