<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12L3 11L5.83 11L11.06 11C11.4 7.9 13.32 5.28 16 3.95C16.04 2.31 17.36 1 19 1C20.66 1 22 2.34 22 4C22 5.66 20.66 7 19 7C18.05 7 17.22 6.55 16.67 5.86C14.77 6.89 13.41 8.77 13.09 11L16.19 11C16.6 9.84 17.7 9 19 9C20.66 9 22 10.34 22 12C22 13.66 20.66 15 19 15C17.7 15 16.6 14.16 16.18 13L13.08 13C13.4 15.23 14.77 17.1 16.67 18.14C17.22 17.45 18.05 17 19 17C20.66 17 22 18.34 22 20C22 21.66 20.66 23 19 23C17.36 23 16.04 21.69 16.01 20.05C13.33 18.72 11.41 16.1 11.07 13L5.84 13L3 13L3 12Z"
      :fill="active ? 'white' : '#2462D1'"
    />
    <circle cx="5" cy="12" r="3" :fill="active ? 'white' : '#2462D1'" />
  </svg>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "IconScheme",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
