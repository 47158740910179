import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;
  const params = {
    type: root.$route.query.type,
    versionId: root.$route.query.versionId,
  };
  const isOpenModalOnStart = !!root.$route.query.isOpenModal;

  onBeforeMount(() => {
    store.dispatch("docTypeView/getSectionList", params);
    store.dispatch("docTypeView/getStorageTerms");
    store.dispatch("docTypeView/getElasticValue");
    store.dispatch("docTypeView/getViewMode", isOpenModalOnStart);
  });
};

export default useGetTableLibs;
