import { onBeforeMount, onUnmounted, ComponentInstance, computed } from "@vue/composition-api";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import { CardMode } from "@monorepo/utils/src/types/cardMode";

const useModalChangeByKeypress = (
  root: ComponentInstance,
  moduleName: string,
  section: string,
  type: string,
  changeOpenedIdName = "changeOpenedId"
): void => {
  const store = root.$store;

  const cardModeList = computed(() => store.getters["auth/cardModeList"] || {});

  const cardModeResult = computed(() => {
    return cardModeList.value[section as string] || CardMode.DEFAULT;
  });

  const changeOpenedId = (value: changeOpenedIdPayloadValues) => {
    store.dispatch(`${moduleName}/${changeOpenedIdName}`, value);
  };

  const checkKeyPressToChangeOpenedId = (e: Event): void => {
    if (cardModeResult.value === CardMode.FULL) {
      return;
    }

    if ((e as KeyboardEvent).key === "ArrowDown") {
      changeOpenedId(changeOpenedIdPayloadValues.DOWN);
    }

    if ((e as KeyboardEvent).key === "ArrowUp") {
      changeOpenedId(changeOpenedIdPayloadValues.UP);
    }
  };

  onBeforeMount(() => {
    if (type !== ModalType.NEW) {
      window.addEventListener("keydown", checkKeyPressToChangeOpenedId);
    }
  });

  onUnmounted(() => {
    if (type !== ModalType.NEW) {
      window.removeEventListener("keydown", checkKeyPressToChangeOpenedId);
    }
  });
};

export default useModalChangeByKeypress;
