import { convertTreeItemsToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/docTypeView/fields";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertTreeItemsToApi(resultFilters, fields.FILTER_SECTION, "id");
  delete resultFilters.fieldFilters.keyword;
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_MORPH];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_NGRAMED];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_SYNONYM];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_FREQUENCY];
};

export const convertSearchFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  convertTreeItemsToApi(resultFilters, fields.FILTER_SECTION, "id");
};
