import { ComponentInstance, ComputedRef, computed } from "@vue/composition-api";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import eventBus from "@monorepo/utils/src/eventBus";
import { DELETE_EVENTS_ACTIONS } from "@monorepo/utils/src/eventBus/events/deleteEvents";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

interface IUseFooterActions {
  isShowDeleteBtn: ComputedRef<boolean>;
  isShowRestoreBtn: ComputedRef<boolean>;
  openDeleteModal: () => void;
  openRestoreModal: () => void;
}

const useFooterActions = (
  root: ComponentInstance,
  moduleName: string,
  pluralFormTitles = ["Элемент"],
  canDeleteAuthority: authorities,
  customFieldNames?: { data?: string; selectedIds?: string; isSelectAll?: string; totalLength?: string; deleteElement?: string }
): IUseFooterActions => {
  const store = root.$store;

  const user = computed(() => store.getters[`auth/user`] || {});

  const data = computed(() => store.getters[`${moduleName}/${customFieldNames?.data || "data"}`] || []);

  const selectedIds = computed(() => store.getters[`${moduleName}/${customFieldNames?.selectedIds || "selectedIds"}`] || {});

  const isSelectAll = computed(() => store.getters[`${moduleName}/${customFieldNames?.isSelectAll || "isSelectAll"}`] || false);

  const totalLength = computed(() => store.getters[`${moduleName}/${customFieldNames?.totalLength || "totalLength"}`] || 0);

  const isCanDelete = computed(() => {
    return isAuthorityExist(user.value, canDeleteAuthority);
  });

  const selectedLength = computed(() => {
    return isSelectAll.value ? totalLength.value : Object.values(selectedIds.value).filter(Boolean).length;
  });

  const selectedNotDeletedLength = computed(() => {
    const deletedItemsObject = data.value.reduce((result: { [key: string]: boolean }, item: Record<string, string | boolean>) => {
      result[item.id as string] = item.isDeleted as boolean;
      return result;
    }, {});
    return Object.entries(selectedIds.value).filter(([key, value]) => {
      return value && !deletedItemsObject[key];
    }).length;
  });

  const selectedDeletedLength = computed(() => {
    const deletedItemsObject = data.value.reduce((result: { [key: string]: boolean }, item: Record<string, string | boolean>) => {
      result[item.id as string] = item.isDeleted as boolean;
      return result;
    }, {});

    return Object.entries(selectedIds.value).filter(([key, value]) => {
      return value && deletedItemsObject[key];
    }).length;
  });

  const isShowDeleteBtn = computed(() => {
    if (!isCanDelete.value) {
      return false;
    }

    const canBeDeleted = data.value.filter((item: Record<string, string | boolean>) => !item.isDeleted);
    const cannotBeDeleted = data.value.filter((item: Record<string, string | boolean>) => !!item.isDeleted);

    if (isSelectAll.value && canBeDeleted.length && !cannotBeDeleted.length) {
      return true;
    }

    return !!selectedNotDeletedLength.value && !selectedDeletedLength.value;
  });

  const isShowRestoreBtn = computed(() => {
    if (!isCanDelete.value) {
      return false;
    }

    const canBeDeleted = data.value.filter((item: Record<string, string | boolean>) => !item.isDeleted);
    const cannotBeDeleted = data.value.filter((item: Record<string, string | boolean>) => !!item.isDeleted);

    if (isSelectAll.value && !canBeDeleted.length && !!cannotBeDeleted.length) {
      return true;
    }

    return !selectedNotDeletedLength.value && !!selectedDeletedLength.value;
  });

  const restoreEntity = (id: string) => {
    return store.dispatch(`${moduleName}/restoreEntity`, id);
  };

  const setSelectedIds = (value: Record<string, boolean>) => {
    store.dispatch(`${moduleName}/setSelectedIds`, value);
  };

  const refreshScroll = () => {
    store.dispatch(`${moduleName}/refreshScroll`);
  };

  const deleteElement = async (payload: Record<string, unknown>) => {
    return await store.dispatch(`${moduleName}/${customFieldNames?.deleteElement || "deleteElement"}`, payload);
  };

  const restoreEntitiesCb = async () => {
    const restoreIds = Object.keys(selectedIds.value).filter((key) => selectedIds.value[key]);
    const promises = restoreIds.map((id) => restoreEntity(id));
    const isRestored = await Promise.all<boolean>(promises as PromiseLike<boolean>[]).catch(() => {
      showNotification("Произошла ошибка во время восстановления.");
    });
    if (isRestored?.filter((item) => item).length) {
      setSelectedIds({});
      refreshScroll();
    } else {
      showNotification(`Ошибка восстановления ${pluralFormTitles[2] || pluralFormTitles[0]}`);
    }
  };

  const deleteElementCb = async () => {
    eventBus.$emit(DELETE_EVENTS_ACTIONS.DELETE);
    const isDeleted = await deleteElement(
      isSelectAll.value ? { deleteAll: true } : { ids: Object.keys(selectedIds.value).filter((key) => selectedIds.value[key]) }
    );
    if (isDeleted) {
      setTimeout(() => {
        setSelectedIds({});
        refreshScroll();
      }, 800);
    } else {
      showNotification(`Ошибка удаления ${pluralFormTitles[2] || pluralFormTitles[0]}`);
    }
  };

  const openDeleteModal = () => {
    let title = "";

    if (selectedNotDeletedLength.value > 1) {
      title = `Удалить ${selectedNotDeletedLength.value} ${getPluralNoun(selectedLength.value, ...pluralFormTitles)}?`;
    } else {
      const key = Object.keys(selectedIds.value).filter((key) => selectedIds.value[key])[0];
      const element = data.value.find((el: Record<string, string | boolean>) => +el.id === +key);
      title = `Удалить ${pluralFormTitles[0]} «${element?.name || ""}»?`;
    }

    eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "DeleteModal", { cbOnDelete: deleteElementCb, title }));
  };

  const openRestoreModal = () => {
    let title = "";

    if (selectedDeletedLength.value > 1) {
      title = `Восстановить ${selectedDeletedLength.value} ${getPluralNoun(selectedLength.value, ...pluralFormTitles)}?`;
    } else {
      const key = Object.keys(selectedIds.value).filter((key) => selectedIds.value[key])[0];
      const element = data.value.find((el: Record<string, string | boolean>) => +el.id === +key);
      title = `Восстановить ${pluralFormTitles[0]} «${element?.name || ""}»?`;
    }

    eventBus.$emit(
      MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
      formModalPayload(true, "DeleteModal", {
        cbOnDelete: restoreEntitiesCb,
        title,
        yesBtnTitle: "Да, восстановить",
        icon: "mdi-delete-restore",
      })
    );
  };

  return {
    isShowDeleteBtn,
    isShowRestoreBtn,
    openDeleteModal,
    openRestoreModal,
  };
};

export default useFooterActions;
