import { render, staticRenderFns } from "./CardModalInfoElement.vue?vue&type=template&id=42b96a91&scoped=true&"
import script from "./CardModalInfoElement.vue?vue&type=script&lang=ts&"
export * from "./CardModalInfoElement.vue?vue&type=script&lang=ts&"
import style0 from "./CardModalInfoElement.vue?vue&type=style&index=0&id=42b96a91&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b96a91",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
