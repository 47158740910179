import { get } from "lodash";

export const convertToSelectItems = (
  library: string[] | Record<string, unknown>[],
  titleKey?: string,
  valueKey?: string
): { title: string; value: string }[] => {
  if (!Array.isArray(library)) {
    return [];
  }
  if (typeof library[0] === "string") {
    return (library as string[]).map((key: string) => ({
      title: key,
      value: key,
    }));
  } else if (titleKey && valueKey) {
    return (library as Record<string, unknown>[]).map((item: Record<string, unknown>) => ({
      title: get(item, titleKey) as string,
      value: get(item, valueKey) as string,
    }));
  } else {
    return [];
  }
};
