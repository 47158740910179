export enum DocumentTypes {
  MANAGEMENT_DOCUMENTS = "MANAGEMENT_DOCUMENTS",
  CREDIT_DOCUMENTS_ORGANIZATIONS = "CREDIT_DOCUMENTS_ORGANIZATIONS",
  NORMATIVE_TECHNICAL_DOCUMENTATIONS = "NORMATIVE_TECHNICAL_DOCUMENTATIONS",
}

export enum DocumentTypesLabels {
  MANAGEMENT_DOCUMENTS = "Управленческие документы",
  CREDIT_DOCUMENTS_ORGANIZATIONS = "Документы кредитных организаций",
  NORMATIVE_TECHNICAL_DOCUMENTATIONS = "НТД",
}
