import { ComponentInstance } from "@vue/composition-api";
import { Route } from "vue-router";

const useSetStartModal = (root: ComponentInstance, moduleName: string, actionName = "addOpenedId"): void => {
  const query = (root.$route as Route).query || {};

  if (query?.card) {
    root.$store.dispatch(`${moduleName}/${actionName}`, query?.card);
  }
};

export default useSetStartModal;
