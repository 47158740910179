export interface IEADFields {
  EAD_ID: string;
  EAD_TITLE: string;
  EAD_NUMBER: string;
  EAD_STATUS: string;
  EAD_STORAGE_PERIOD: string;
  RECEIVE_DATE: string;
  REGISTRATION_DATE: string;
  REGISTRATION_DATE_TO: string;
  REGISTRATION_DATE_FROM: string;
  REGISTRATION_TIME_TO: string;
  REGISTRATION_TIME_FROM: string;
  FUND_NUMBER: string;
  EAD_CASE_INDEX: string;
  EAD_CASE_TITLE: string;
  EAD_RESTRICTED: string;
  SIGNATURE: string;
  OIK_SHORT_NAME: string;
  EAD_CREATION_DATE: string;
  DOCUMENT_FILES: string;
  COMMENT: string;
  COMMENT_UPDATE_DATE: string;
  COMMENT_UPDATE_USER: string;
  EAD_TYPE: string;
  EAD_ARCHIVE_CIPHER: string;
  EAD_CASE_ARCHIVE_CIPHER: string;
  INVENTORY_NUMBER: string;
  INVENTORY_YEAR: string;
  AK_ID: string;
  DOC_TYPE_ID: string;
  DOC_SECTION: string;
  DOC_DOC: string;
  ARCHIVE_NAME: string;
  ARCHIVE_ADDRESS: string;
  FUND_NAME: string;
  CASE_NUMBER: string;
  STORAGE_EXPIRATION_DATE: string;
  EAD_AUTHENTICITY: string;

  FILTER_TITLE: string;
  FILTER_KEYWORD: string;
  FILTER_REG_NUMBER: string;
  FILTER_CIPHER_DOC: string;
  FILTER_CIPHER_CASE: string;
  FILTER_INDEX: string;
  FILTER_TITLE_CASE: string;
  FILTER_OIK: string;
  FILTER_FUND: string;
  FILTER_DATE_RECEIVED: string;
  FILTER_CHIPBOARD: string;
  FILTER_DATE_RECEIVED_FROM: string;
  FILTER_DATE_RECEIVED_TO: string;
  FILTER_TIME_RECEIVED_FROM: string;
  FILTER_TIME_RECEIVED_TO: string;
  FILTER_STATUS: string;
  FILTER_SEARCH_MORPH: string;
  FILTER_SEARCH_NGRAMED: string;
  FILTER_SEARCH_SYNONYM: string;
  FILTER_SEARCH_FREQUENCY: string;
  FILTER_DOC_TYPE: string;

  STORAGE_STATUS: string;
  STORAGE_STATUS_COMMENT: string;
  STORAGE_ARCHIVE: string;
  STORAGE_ADDRESS: string;
  STORAGE_HOUSE: string;
  STORAGE_FLOOR: string;
  STORAGE_ARCHIVE_STORAGE: string;
  STORAGE_ROOM: string;
  STORAGE_SHELVING: string;
  STORAGE_CABINET: string;
  STORAGE_SHELF: string;
  STORAGE_COMMENT: string;
  STORAGE_DATE: string;
}

export const fields: IEADFields = {
  EAD_ID: "id",
  EAD_TITLE: "title",
  SIGNATURE: "digitalSignatures",
  EAD_STATUS: "eadStatus",
  EAD_NUMBER: "number",
  EAD_STORAGE_PERIOD: "storagePeriod",
  REGISTRATION_DATE: "registrationDate",
  RECEIVE_DATE: "arrivalDate",
  OIK_SHORT_NAME: "oikId",
  FUND_NUMBER: "fundNumber",
  REGISTRATION_DATE_TO: "registrationDateTo",
  REGISTRATION_DATE_FROM: "registrationDateFrom",
  REGISTRATION_TIME_TO: "registrationTimeTo",
  REGISTRATION_TIME_FROM: "registrationTimeFrom",
  EAD_CASE_INDEX: "caseIndex",
  EAD_CASE_TITLE: "caseHeader",
  EAD_RESTRICTED: "isDSP",
  EAD_CREATION_DATE: "createDate",
  DOCUMENT_FILES: "files",
  COMMENT: "comment",
  COMMENT_UPDATE_DATE: "commentUpdateDate",
  COMMENT_UPDATE_USER: "commentUpdateUser",
  EAD_TYPE: "storageKind",
  EAD_ARCHIVE_CIPHER: "archiveCipher",
  EAD_CASE_ARCHIVE_CIPHER: "caseArchiveCipher",
  INVENTORY_NUMBER: "inventoryNumber",
  INVENTORY_YEAR: "inventoryYear",
  AK_ID: "akId",
  DOC_TYPE_ID: "documentTypeId",
  DOC_SECTION: "documentTypeSection",
  DOC_DOC: "documentTypeDoc",
  ARCHIVE_NAME: "archiveName",
  ARCHIVE_ADDRESS: "archiveAddress",
  FUND_NAME: "fundName",
  CASE_NUMBER: "caseNumber",
  STORAGE_EXPIRATION_DATE: "storageExpirationDate",
  EAD_AUTHENTICITY: "authenticity",

  FILTER_KEYWORD: "keyword",
  FILTER_TITLE: "title",
  FILTER_REG_NUMBER: "number",
  FILTER_CIPHER_DOC: "archiveCipher",
  FILTER_CIPHER_CASE: "caseArchiveCipher",
  FILTER_INDEX: "caseIndex",
  FILTER_TITLE_CASE: "caseTitle",
  FILTER_OIK: "oikCode",
  FILTER_FUND: "fundNumber",
  FILTER_DATE_RECEIVED: "creationDate",
  FILTER_CHIPBOARD: "isDsp",
  FILTER_DATE_RECEIVED_FROM: "creationDateFrom",
  FILTER_DATE_RECEIVED_TO: "creationDateTo",
  FILTER_TIME_RECEIVED_FROM: "creationTimeFrom",
  FILTER_TIME_RECEIVED_TO: "creationTimeTo",
  FILTER_STATUS: "status",
  FILTER_SEARCH_MORPH: "morphology",
  FILTER_SEARCH_NGRAMED: "ngramed",
  FILTER_SEARCH_SYNONYM: "synonymysed",
  FILTER_SEARCH_FREQUENCY: "frequency-term",
  FILTER_DOC_TYPE: "documentType",

  STORAGE_STATUS: "storageStatus",
  STORAGE_STATUS_COMMENT: "statusComment",
  STORAGE_ARCHIVE: "storageArchive",
  STORAGE_ADDRESS: "address",
  STORAGE_HOUSE: "house",
  STORAGE_FLOOR: "floor",
  STORAGE_ARCHIVE_STORAGE: "archiveStorage",
  STORAGE_ROOM: "room",
  STORAGE_SHELVING: "shelving",
  STORAGE_CABINET: "cabinet",
  STORAGE_SHELF: "shelf",
  STORAGE_COMMENT: "additionalInfoOfPlacementAddress",
  STORAGE_DATE: "dateTransferToStorage",
};
