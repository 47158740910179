



































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CardModalStatusChip",
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    secondBackgroundColor: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
    secondStatus: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "Статус",
    },
    secondTooltip: {
      type: String,
    },
    color: {
      type: String,
      default: "#000",
    },
    secondColor: {
      type: String,
      default: "#000",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isShowSecondChip: {
      type: Boolean,
      default: false,
    },
  },
});
