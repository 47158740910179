import { ComponentInstance, computed, ComputedRef } from "@vue/composition-api";

interface IUseTableAnimation {
  isShowAnimation: ComputedRef<string>;
  clearAnimation: (e: Event) => void;
  addAnimation: (e: MouseEvent) => void;
}

const useTableAnimation = (root: ComponentInstance): IUseTableAnimation => {
  const store = root.$store;
  const isShowAnimation = computed(() => store.getters["auth/isShowAnimation"]);

  const clearAnimation = (e: Event) => {
    if (!isShowAnimation.value) {
      return;
    }
    (e.target as HTMLElement).style.removeProperty("background");
  };
  const addAnimation = (e: MouseEvent) => {
    if (!isShowAnimation.value) {
      return;
    }
    //const tr = (e as unknown as { path: HTMLElement[] }).path?.find((el: HTMLElement) => el.classList.contains("monitoring-table__tr"));
    const tr = (e?.target as HTMLElement).closest(".monitoring-table__tr") as HTMLElement;
    if (tr) {
      const rect = tr.getBoundingClientRect();
      const tableContainer = document.querySelector(".container.table-view");
      if (tableContainer) {
        const tableRect = tableContainer.getBoundingClientRect();
        const y = rect.top - tableRect.top;
        tr.style.setProperty("--y", `${y}px`);
        tr.style.setProperty("--trH", `${rect.height}px`);
        const width = e.view?.innerWidth || 0;
        const xObject = addXProperty(width, e.clientX, tableRect.left, rect.left);
        setXProperty(tr, xObject.x, xObject.trX);
      }
    }
  };
  const addXProperty = (width: number, clientX: number, tableRectLeft: number, rectLeft: number) => {
    let x = 0;
    let trX = 0;
    if (width < 2048) {
      x = clientX - tableRectLeft - 15;
      trX = clientX - rectLeft - 15;
    } else if (width < 3072) {
      x = (clientX - tableRectLeft) / 1.5 - 75;
      trX = (clientX - tableRectLeft) / 1.5 - 75 + (tableRectLeft - rectLeft);
    } else if (width < 5121) {
      x = (clientX - tableRectLeft) / 2 - 100;
      trX = (clientX - tableRectLeft) / 2 - 100 + (tableRectLeft - rectLeft);
    } else {
      x = (clientX - tableRectLeft) / 3 - 135;
      trX = (clientX - tableRectLeft) / 3 - 135 + (tableRectLeft - rectLeft);
    }
    return { x, trX };
  };
  const setXProperty = (el: HTMLElement, x: number, trX: number) => {
    el.style.setProperty("--x", `${x}px`);
    el.style.setProperty(
      "background",
      `linear-gradient(to right, #dedede, #ededed ${trX - 50}px, #ededed ${trX - 30}px , #fff ${trX}px, #ededed ${trX + 50}px, #dedede 100%)`
    );
  };

  return {
    isShowAnimation,
    clearAnimation,
    addAnimation,
  };
};

export default useTableAnimation;
