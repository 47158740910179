











import { defineComponent } from "@vue/composition-api";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "AddElement",
  props: {
    tooltip: {
      type: String,
      default() {
        return "";
      },
    },
    dataEducationId: {
      type: String,
    },
  },
  data() {
    return {
      educationIds,
    };
  },
  methods: {
    addElement() {
      this.$emit("addElement");
    },
  },
});
