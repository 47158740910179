import { IDocTypeElement } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";

export const convertSaveCardObject = (resultData: Partial<IDocTypeElement>): Partial<IDocTypeElement> => {
  return {
    id: resultData.id || "",
    name: resultData.name || "",
    article: resultData.article ? Number(resultData.article) : null,
    storageTermValue: resultData.storageTermValue ? { id: resultData.storageTermValue.id } : null,
    comment: resultData.comment,
    storageTermList: (resultData.storageTermList || []).map((storageItem: any) => ({
      ...storageItem,
      storageTermValue: storageItem.storageTermValue ? { id: storageItem.storageTermValue.id } : null,
    })),
  };
};
