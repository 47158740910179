






















import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ExportBtn",
  props: {
    ml: {
      type: Boolean,
      default: false,
    },
    mr: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Экспорт",
    },
    tooltip: {
      type: String,
      default: "Экспорт в PDF",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("app", ["isExportFileLoading"]),
  },
});
