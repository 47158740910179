import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/docTypeView/fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование секции",
    tooltip: "Наименование секции",
    className: "info-modal__element_full-size",
    value: fields.SECTION_NAME,
    isRequired: true,
    isShowContentTooltip: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Вид документа",
    tooltip: "Вид документа",
    className: "info-modal__element_full-size",
    value: fields.NAME,
    isRequired: true,
    isShowContentTooltip: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статья",
    tooltip: "Статья",
    className: "info-modal__element_title",
    value: fields.ARTICLE,
    isRequired: true,
    isShowContentTooltip: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Срок хранения",
    tooltip: "Срок хранения",
    className: "info-modal__element_title",
    value: fields.STORAGE_TERM_VALUE,
    slotName: "storageTermValue",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Примечание",
    tooltip: "Примечание",
    className: "info-modal__about info-modal__element_full-size info-modal__element_textarea",
    value: fields.COMMENT,
    isShowContentTooltip: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    fieldType: "textarea",
  },
];

export const techElements = [];

export const fullModalElements = [...baseModalElements, ...techElements];
