import { ComponentInstance, onBeforeMount, onUnmounted, computed } from "@vue/composition-api";

interface IUseUniqueLinkModal {
  addQueryOpenedId: () => void;
}

const useUniqueLinkModal = (root: ComponentInstance, moduleName: string, openedIdName = "openedId"): IUseUniqueLinkModal => {
  const router = root.$router || {};
  const route = computed(() => root.$route || {});
  const store = root.$store;
  const openedId = computed(() => store.getters[`${moduleName}/${openedIdName}`] || []);

  const addQueryOpenedId = () => {
    return router.replace({ query: { ...(route.value.query || {}), card: openedId.value } }).catch(console.warn);
  };

  onBeforeMount(() => {
    const query = Object.assign({}, route.value.query);
    if (query.isOpenModal) {
      delete query.isOpenModal;
      router.replace({ query: { ...query } });
    }
  });

  onUnmounted(() => {
    const query = Object.assign({}, route.value.query);
    if (query.card) {
      delete query.card;
      if (query.previewFileId) {
        delete query.previewFileId;
      }
      router.replace({ query: { ...query } });
    }
  });

  return {
    addQueryOpenedId,
  };
};

export default useUniqueLinkModal;
