import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/docTypeView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  {
    text: "Идентификатор",
    tooltip: "Идентификатор",
    defaultWidth: 130,
    value: fields.ID,
    isHiddenByDefault: true,
    isLink: false,
    isSorted: false,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Наименование секции",
    tooltip: "Наименование секции",
    defaultWidth: 300,
    value: fields.SECTION_NAME,
    sortField: "section.name",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статья",
    tooltip: "Статья",
    defaultWidth: 220,
    value: fields.ARTICLE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид документа",
    tooltip: "Вид документа",
    defaultWidth: 350,
    value: fields.NAME,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Срок хранения",
    tooltip: "Срок хранения",
    defaultWidth: 450,
    value: fields.STORAGE_TERM_VALUE,
    isLink: false,
    isHandle: true,
    id: uuid(),
    slotName: "storageTerms",
  },
  {
    text: "Примечание",
    tooltip: "Примечание",
    defaultWidth: 220,
    value: "comment",
    isLink: false,
    isHandle: true,
    id: uuid(),
  },
];
