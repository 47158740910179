








































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";

export default defineComponent({
  name: "FormInputElement",
  components: {
    CardModalInfoElement,
    DatePicker,
  },
  inject: {
    formulateSetter: { default: undefined },
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  props: {
    isShowContentTooltip: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isDisable: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: "",
    },
    maxLength: {
      type: String,
      default: "",
    },
    minLength: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    patternTitle: {
      type: String,
      default: "",
    },
    isShowErrorText: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: undefined,
    },
    rows: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    emitChangeForm(e: Event) {
      (this.formulateSetter as (name: string, value: string) => void)(this.name, (e.target as HTMLInputElement)?.value);
    },
    emitChangeDateForm(date: string) {
      (this.formulateSetter as (name: string, value: string) => void)(this.name, date);
    },
  },
});
