





















































































import { defineComponent, computed } from "@vue/composition-api";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import TableSearchResults from "./TableSearchResults.vue";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { mapGetters } from "vuex";
import { AuthResponse } from "@monorepo/authorization/src/views/Login/types/authResponse";
import eventBus from "@monorepo/utils/src/eventBus";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";
import Vue from "vue";
import useTable from "@monorepo/utils/src/composables/useTable";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import useTableAnimation from "@monorepo/utils/src/composables/useTableAnimation";

export default defineComponent({
  components: {
    OpenCardButton,
    IdCell,
    TableSearchResults,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    isShowOpenCardBtn: {
      type: Boolean,
      default: true,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    viewUniqKey: {
      type: String,
      default: "",
    },
    convertItemCb: {
      type: Function,
      default: () => ({}),
    },
    openCardButtonTitle: {
      type: String,
    },
    additionalClassObj: {
      type: Function,
      default: () => ({}),
    },
    idCellFieldName: {
      type: String,
      default: "id",
    },
    statusFieldName: {
      type: String,
      default: "",
    },
    tableScrollId: {
      type: String,
      default: "virtual-scroll-table",
    },
    isTopTableData: {
      type: Boolean,
      default: false,
    },
    refTableName: {
      type: String,
    },
    convertedData: {
      type: Array,
    },
    cellsGetterName: {
      type: String,
      default: "cells",
    },
    keyFieldsArray: {
      type: Array,
    },
  },
  mounted() {
    if (this.refTableName) {
      eventBus.$on(MONITORING_EVENT_BUS_EVENTS.ADD_NEW_EVENT, this.scrollNewEvent);
    }
  },
  beforeDestroy() {
    if (this.refTableName) {
      eventBus.$off(MONITORING_EVENT_BUS_EVENTS.ADD_NEW_EVENT, this.scrollNewEvent);
    }
  },
  data() {
    return {
      headerCheckboxValue,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    sort(): Record<string, SORT_TYPE> {
      return this.$store.getters[`${this.sectionName}/sort`];
    },
    isSelectAll(): boolean {
      return this.$store.getters[`${this.sectionName}/isSelectAll`];
    },
    openedId(): string {
      return this.$store.getters[`${this.sectionName}/openedId`];
    },
    selectedIds(): Record<string, boolean> {
      return this.$store.getters[`${this.sectionName}/selectedIds`];
    },
    tableRowKey() {
      return (event: Record<string, unknown>) => {
        return this.keyFieldsArray ? this.keyFieldsArray.map((key: unknown) => event[key as string] as string).join("") : event.id;
      };
    },
    baseData(): Record<string, unknown>[] {
      return this.$store.getters[`${this.sectionName}/data`];
    },
    topTableData(): Record<string, unknown>[] {
      return this.$store.getters[`${this.sectionName}/topTableData`];
    },
    data(): Record<string, unknown>[] {
      if (this.isTopTableData) {
        return this.baseData && this.baseData.length ? this.topTableData : [];
      }
      return this.baseData;
    },
    getCells(): string[] {
      if (this.isTopTableData) {
        return [];
      }
      return this.$store.getters[`${this.sectionName}/${this.cellsGetterName}`];
    },
    resultData(): Record<string, unknown>[] {
      return (
        (this.convertedData as Record<string, unknown>[]) ||
        this.data.map((item: Record<string, unknown>) =>
          (this.convertItemCb as unknown as (itemObj: Record<string, unknown>, user: AuthResponse) => Record<string, unknown>)(item, this.user)
        )
      );
    },
  },
  methods: {
    setSelectedIds(value: Record<string, boolean>) {
      this.$store.dispatch(`${this.sectionName}/setSelectedIds`, value);
    },
    setIsSelectAll(value: boolean | null) {
      this.$store.dispatch(`${this.sectionName}/setIsSelectAll`, value);
    },
    openModal(header: { value: string; isLink: boolean }, event: Record<string, unknown>) {
      this.$emit("openModal", { header, event });
    },
    scrollNewEvent() {
      if (!this.refTableName) {
        return;
      }
      this.$nextTick(() => {
        if (this.sort.date === "DESC") {
          document.querySelector(".table-view__base-wrap")?.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          (this.$refs.table as Vue).$el.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });
    },
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => props.tableHeaders);
    const { clickCheckbox, isElementDeleted, selectedIdsElements } = useTable(root, props.sectionName);
    const { headers } = useTableCells(root, props.sectionName, tableHeaders, 1120, false, props.isTopTableData);
    const { isShowAnimation, clearAnimation, addAnimation } = useTableAnimation(root);
    return {
      clickCheckbox,
      isElementDeleted,
      selectedIdsElements,
      headers,
      isShowAnimation,
      clearAnimation,
      addAnimation,
    };
  },
});
