import { ComponentInstance } from "@vue/composition-api";
import { debounce } from "lodash";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";

interface IUseSortPanelContainer {
  changeSort: (currentType: SORT_TYPE) => void;
  addSort: (value: Record<string, unknown>) => void;
  refreshScrollDebounce: () => void;
}

const useSortPanelContainer = (
  root: ComponentInstance,
  moduleName: string,
  id: string,
  customFieldNames?: { addSort?: string; refreshScroll?: string },
  getQueryCb?: () => void,
  additionalIds?: Record<string, string[]>
): IUseSortPanelContainer => {
  const store = root.$store;

  const addSort = (value: Record<string, unknown>) => {
    store.dispatch(`${moduleName}/${customFieldNames?.addSort || "addSort"}`, value);
  };

  const refreshScroll = () => {
    store.dispatch(`${moduleName}/${customFieldNames?.refreshScroll || "refreshScroll"}`);
  };

  const refreshScrollDebounce = debounce(function () {
    refreshScroll();
  }, 500);

  const changeSort = (currentType: SORT_TYPE) => {
    if (additionalIds?.[id]) {
      const resultSort = additionalIds?.[id].reduce((res, current) => {
        res[current] = currentType;
        return res;
      }, {} as Record<string, SORT_TYPE>);
      addSort(resultSort);
    } else {
      addSort({ [id]: currentType });
    }
    refreshScrollDebounce();
    if (getQueryCb) {
      getQueryCb();
    }
  };

  return {
    changeSort,
    addSort,
    refreshScrollDebounce,
  };
};

export default useSortPanelContainer;
