




import { defineComponent } from "@vue/composition-api";
import SortPanel from "@monorepo/uikit/src/components/tableViews/SortPanel.vue";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import useSortPanelContainer from "@monorepo/utils/src/composables/useSortPanelContainer";

export default defineComponent({
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    additionalIds: {
      type: Object,
    },
    customFieldNames: {
      type: Object,
    },
    getQueryCb: {
      type: Function,
    },
  },
  components: {
    SortPanel,
  },
  computed: {
    sort(): Record<string, SORT_TYPE> {
      return this.$store.getters[`${this.sectionName}/${this.customFieldNames?.sort || "sort"}`];
    },
  },
  setup(props, { root }) {
    const { changeSort } = useSortPanelContainer(
      root,
      props.sectionName,
      props.id,
      props.customFieldNames || {},
      props.getQueryCb,
      props.additionalIds
    );

    return {
      changeSort,
    };
  },
});
