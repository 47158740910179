






import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

const copyIcon = `${environmentVariables.BASE_URL}assets/images/ead/copy-btn.svg`;
export default defineComponent({
  name: "CopyBtn",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      copyIcon,
    };
  },
  methods: {
    copy() {
      const el = document.createElement("textarea");
      el.value = this.value.toString();
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
});
