export const standardMask = {
  guid: {
    regex:
      "^(?:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F][0-9a-fA-F]{3}-[0-9a-fA-F][0-9a-fA-F]{3}-[0-9a-fA-F]{12}|00000000-0000-0000-0000-000000000000)$",
  },
  phone: {
    mask: "8 (999) 999-99-99",
  },
  email: "email",
  maskNotStartWithSpace: {
    regex: '^[0-9a-zA-Zа-яА-Я-():;#№!&?Ёё<>/+-=%^*@"«».,~][0-9a-zA-Zа-яА-Я-_():;#№!&?Ёё<>/+-=%^*@"«».,~\\s]+$',
    placeholder: "",
  },
  number: {
    regex: "[0-9]+",
  },
  cron: {
    regex: "[0-9*A-Z/ ]*",
  },
};
