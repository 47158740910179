












import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import Loading from "@monorepo/uikit/src/components/tableViews/Loading.vue";
import Footer from "@monorepo/uikit/src/components/tableViews/Footer.vue";
import { defineComponent, PropType } from "@vue/composition-api";
import { debounce } from "lodash";

type Error = { __CANCEL__?: boolean };

export default defineComponent({
  name: "InfiniteScroll",
  components: {
    InfiniteLoading,
    Loading,
    Footer,
  },
  props: {
    params: {
      type: Object,
    },
    needFooter: {
      type: Boolean,
      default: true,
    },
    infiniteId: {
      type: String,
      default: "",
    },
    getEventList: {
      type: Function as PropType<(params?: any) => Promise<{ data: Record<string, unknown>[]; totalLength: number; error?: Error }>>,
      default: () => Promise.resolve({ data: [], totalLength: 0 }),
    },
  },
  methods: {
    infiniteHandlerDebounce: debounce(
      function (this: { infiniteHandler: ($state: StateChanger) => Promise<void> }, $state: StateChanger) {
        this.infiniteHandler($state);
      },
      1000,
      { leading: false, trailing: true }
    ),
    infiniteHandler($state: StateChanger) {
      this.getEventList(this.params).then(({ data, totalLength, error }) => {
        this.$emit("getEventListFinish");
        if (error?.__CANCEL__) {
          $state.loaded();
          return;
        } else if (data === null) {
          $state.loaded();
          $state.complete();
          return;
        }

        if (data.length < totalLength) {
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      });
    },
  },
});
