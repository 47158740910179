







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "RestoreFooterButton",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
