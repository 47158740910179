var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.status)?_c('div',{staticClass:"status-chip d-flex flex-row"},[(_vm.isLoading)?_c('TmpBlock',{staticClass:"status-chip__text",attrs:{"height":"20","width":"110"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"status-chip__text",style:({
          backgroundColor: _vm.backgroundColor,
          color: _vm.color,
        })},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.status)+" ")])]}}],null,false,3286706238)},[_c('div',[_vm._v(_vm._s(_vm.tooltip))])]),(_vm.isShowSecondChip && !_vm.isLoading)?_c('v-tooltip',{attrs:{"disabled":!_vm.secondTooltip,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2 status-chip__text",style:({ backgroundColor: _vm.secondBackgroundColor || _vm.backgroundColor, color: _vm.secondColor })},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.secondStatus)+" ")])]}}],null,false,2520454571)},[_c('div',[_vm._v(_vm._s(_vm.secondTooltip))])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }