







import { defineComponent } from "@vue/composition-api";
import CopyBtn from "./CopyBtn.vue";

export default defineComponent({
  name: "IdCell",
  components: {
    CopyBtn,
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    isLink: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
});
