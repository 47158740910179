import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertFiltersCustomToApi, convertSearchFiltersCustomToApi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { IDocChapter, IDocSection, IDocSectionsMeta, IDocTypeElement } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { DocumentTypes } from "@monorepo/utils/src/types/documentTypes";
import { IVersionElement } from "@monorepo/catalog/src/views/VersionCatalogView/types/versionElement";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { IResponse } from "@monorepo/utils/src/api/types/response";
import { cloneDeep } from "lodash";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  meta: IDocSectionsMeta | null;
  data: IDocTypeElement[];
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  infiniteId: string;
  cells: string[];
  isOpenFilters: boolean;
  libraries: {
    sections: IDocSection[];
    storageTerms: { id: string; value: string }[];
  };
  section: Sections;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  settingElasticValue: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

export interface IDocTypeHierarchyElement extends IDocTypeElement {
  funds: IFundElement[];
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    [fields.FILTER_SEARCH_MORPH]: true,
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_CSV;
    case "XLSX":
      return QUERY_PATH.GET_CATALOG_ARCHIVE_XLS;
    default:
      return QUERY_PATH.GET_CATALOG_ARCHIVE_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    sectionCells: {} as ISection,
    searchTemplates: [],
    totalLength: 0,
    cells: [],
    isOpenFilters: false,
    meta: null,
    data: [],
    libraries: {
      sections: [],
      storageTerms: [],
    },
    section: Sections.DOC_TYPE,
    isTableLoading: false,
    isLoadingToggleFilters: false,
    settingElasticValue: false,
    viewMode: {
      typeDisplay: ViewMode.TABLE,
    },
  }),
  mutations: {
    ...baseMutations,
    addMeta(state: ITableState, payload: IDocSectionsMeta) {
      state.meta = payload;
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setElasticValue(state: ITableState, payload: boolean) {
      state.settingElasticValue = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setData(state: ITableState, payload: IDocTypeElement[]) {
      state.data = payload;
    },
    addStorageTerms(state: ITableState, payload: { id: string; value: string }[]) {
      state.libraries.storageTerms = payload;
    },
    addSectionLib(state: ITableState, payload: IDocSection[]) {
      state.libraries.sections =
        payload.map((item) => {
          return { ...item, label: item.name };
        }) || [];
    },
    addData(
      state: ITableState,
      payload: {
        data: IDocTypeElement[];
        totalLength: number;
        isReset?: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    refreshInfiniteId(state: ITableState) {
      state.infiniteId = new Date().toString();
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getSearchAutocomplete(info, params: string) {
      if (params?.length < 3) {
        return [];
      }

      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_DOC_TYPE_SEARCH_AUTOCOMPLETE,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        const result = data
          .map((item: { suggestion: string }) => {
            const title = item.suggestion.replaceAll("@!!", "");
            return {
              titleValue: title,
              code: title,
            };
          })
          .filter((item: { titleValue: string }) => item.titleValue !== params);
        return [{ titleValue: params, code: params }].concat(result);
      }
      return [];
    },
    async restoreEntity(info, id: string | number) {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST), { id, isDeleted: false });
        return true;
      } catch (e) {
        return false;
      }
    },
    async updateDocument(info, item: IDocChapter) {
      try {
        await axios.put(`${QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST}/${item.id}`, item);
        return true;
      } catch (e) {
        return false;
      }
    },
    async updateSection(info, { id, data }: { id: string | number; data: { index: number; name: string } }) {
      try {
        await axios.put(`${QUERY_PATH.DOC_TYPE_SECTION}/${id}`, data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async deleteSection(info, id: string | number) {
      try {
        await axios.delete(`${QUERY_PATH.DOC_TYPE_SECTION}/${id}`);
        return true;
      } catch (e) {
        return false;
      }
    },
    async changeVersion(info, item: IVersionElement) {
      try {
        await axios.put(`${QUERY_PATH.GET_CATALOG_VERSIONS_LIST}/${item.id}`, item);
        showNotification("Комментарий успешно сохранён", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка сохранения комментария");
        return false;
      }
    },
    async addNewVersion(info, versionId: string): Promise<boolean> {
      try {
        const { data } = await axios.post(`${QUERY_PATH.GET_CATALOG_VERSIONS_LIST}/${versionId}`);
        return data?.data || {};
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text ||
            (e as unknown as error).response?.data?.message ||
            "Ошибка создания версии справочника."
        );
        return false;
      }
    },
    async activateVersion(info, versionId: string): Promise<boolean> {
      try {
        await axios.put(`${QUERY_PATH.GET_CATALOG_VERSIONS_LIST}/${versionId}`, { state: "ACTIVE" });
        return true;
      } catch (e) {
        showNotification(
          (e as unknown as error).response?.data?.error?.text ||
            (e as unknown as error).response?.data?.message ||
            "Ошибка активации черновика справочника."
        );
        return false;
      }
    },
    async transferDocument(
      info,
      data: {
        documentKindId: number;
        sectionId: number | null;
        index: number;
      }
    ) {
      try {
        await axios.post(getFullPath(QUERY_PATH.TRANSFER_DOCUMENT), data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async transferSection(
      info,
      data: {
        sectionId: number;
        targetSectionId: number | null;
        index: number;
      }
    ) {
      try {
        await axios.post(getFullPath(QUERY_PATH.TRANSFER_SECTION), data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async addNewSection(
      info,
      data: {
        name: string;
        targetSectionId: number | null;
        index: number;
      }
    ) {
      try {
        await axios.post(`${QUERY_PATH.DOC_TYPE_SECTION}/add`, data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async addNewDocument(info, data: IDocTypeElement) {
      try {
        await axios.post(getFullPath(QUERY_PATH.DOC_TYPE_ADD_DOCUMENT), data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getAutocompleteById(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-id/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { id: string }) => ({
          ...item,
          titleValue: item.id,
        }));
      }
      return [];
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async getSectionList({ commit }, { type, versionId }: { type?: string; versionId?: string }) {
      const { data, meta } = await getQuery<IDocChapter[]>(
        `${QUERY_PATH.GET_CATALOG_DOC_TYPE_SECTIONS}/${type || DocumentTypes.MANAGEMENT_DOCUMENTS}`,
        {
          ...(versionId ? { versionId: Number(versionId) } : {}),
        },
        true,
        "",
        "data"
      );
      if (meta) {
        commit("addMeta", meta);
      }
      if (data !== null) {
        commit("addSectionLib", data);
      }
      return [];
    },
    async deleteDocument({ state }, params: Record<string, unknown>) {
      try {
        await axios.delete(getFullPath(QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST), {
          params,
          paramsSerializer,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean | string> {
      try {
        const response = await axios[!data.id ? "post" : "put"](getFullPath(QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST), data).catch(function (error) {
          const messagesArr = Object.values(error?.response?.data?.data || {});
          showNotification(messagesArr.length ? messagesArr.join(". ") : error?.response?.data?.message);
          return Promise.reject(error);
        });
        return response.data.data.id;
      } catch (e) {
        return false;
      }
    },
    async getCardElement(info, id: string) {
      const { data } = await getQuery<IDocTypeElement>(`${QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST}/by-id/${id}`);
      return data;
    },
    async getDocTypeHierarchy({ commit, rootGetters }, { type, versionId }: { type?: string; versionId?: string }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data, meta } = await getQuery<IDocTypeHierarchyElement[]>(
        `${QUERY_PATH.GET_CATALOG_DOC_TYPE_HIERARCHY}/${type || DocumentTypes.MANAGEMENT_DOCUMENTS}`,
        {
          ...(versionId ? { versionId: Number(versionId) } : {}),
        },
        true,
        "",
        "data"
      ).finally(() => {
        commit("setTableLoading", false);
      });
      if (meta) {
        commit("addMeta", meta);
      }
      return data;
    },
    async getElasticValue({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_ELASTIC_SETTING);
      commit("setElasticValue", data === "true");
    },
    async getEventList({ commit, state, rootGetters }, { type, versionId }: { type?: string; versionId?: string }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const {
        data,
        meta,
        total: totalLength,
        error,
      } = await getQuery<IDocTypeElement[]>(
        `${QUERY_PATH.GET_CATALOG_DOC_TYPE_LIST}/${type || DocumentTypes.MANAGEMENT_DOCUMENTS}${keyword ? "/find" : ""}`,
        {
          ...params,
          ...(versionId ? { versionId: Number(versionId) } : {}),
          ...(keyword ? { keyword: keyword?.code } : {}),
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined,
        keyword ? undefined : "page"
      ).finally(() => {
        commit("setTableLoading", false);
      });
      if (meta) {
        commit("addMeta", meta);
      }
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: !!keyword });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), { root: true });
    },
    async saveFundToNewArchive(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_FUND_LIST), data).catch(function (error) {
          showNotification(error.response.data.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getStorageTerms({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.storageTerms, async () => {
          const { data }: AxiosResponse<IResponse<{ id: string; value: string }[]>> = await axios.get(getFullPath(QUERY_PATH.GET_STORAGE_TERMS));
          commit("addStorageTerms", data.data);
        });
      } catch (e) {
        showNotification("Ошибка получения списка сроков хранения.");
      }
    },
  },
  getters: {
    ...baseGetters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    sectionsList(state: ITableState) {
      return state.libraries.sections || [];
    },
    meta(state: ITableState) {
      return state.meta;
    },
    storageTerms(state: ITableState) {
      return state.libraries.storageTerms || [];
    },
    elasticValue(state: ITableState) {
      return state.settingElasticValue;
    },
  },
};
