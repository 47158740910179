import { ComponentInstance } from "@vue/composition-api";

const useMoveByAuthorities = (
  root: ComponentInstance
): ((header: { isLink: boolean }, path: string, query: Record<string, string>) => undefined | void) => {
  const router = root.$router || {};

  return (header: { isLink: boolean }, path: string, query: Record<string, string>): undefined | void => {
    if (!header.isLink) {
      return;
    }
    router.push({
      path,
      query,
    });
  };
};

export default useMoveByAuthorities;
